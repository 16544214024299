#getting-started-header{
    text-align: center;
    margin: 5% 0%;
}

/* Monthly Meetings Section */

#monthly-meetings-row-calendar-icon{
    display: flex;
      justify-content: center;
  }

#monthly-meetings-row{
    background-color: rgba(0, 0, 255, 0.333);
    padding: 10% 2%;
}

#monthly-meeting-header-text{
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 5em;
}

#monthly-budet-text{
    padding: 10%;

    font-size: 2em;
    
}

#budget-group-idea-list{
    
    margin: 2%;
}

.budget-group-idea-item{
font-size: .75em;
background-color: transparent;
border: none;
}
/*                                 */
/* END of Monthly Meetings Section */
/*                                 */

/*                         */
/* Weekly Meetings Section */
/*                         */